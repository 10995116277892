
.swiper-button-prev, .swiper-button-next {
    color: gray!important;
}

/* Apply opacity to all thumbnail slides */
.slider__thumbs .swiper-slide {
    opacity: 0.6;
}

/* Set opacity to 1 for the active thumbnail */
.slider__thumbs .swiper-slide-thumb-active {
    opacity: 1;
}