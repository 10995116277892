@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Inter', sans-serif;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --chart-6: 204 70% 50%; /* Adjusted to a cooler, slightly desaturated cyan */
    --chart-7: 284 65% 45%; /* A soft violet */
    --chart-8: 120 75% 60%; /* A vibrant green */
    --chart-9: 345 80% 55%; /* A bright pink */
    --chart-10: 60 90% 55%; /* A vivid yellow */

    --pastelchart-1: 206 66% 28%;
    --pastelchart-2: 206 66% 34%;
    --pastelchart-3: 199 72% 36%;
    --pastelchart-4: 194 77% 38%;
    --pastelchart-5: 182 52% 42%;
    --pastelchart-6: 163 41% 52%; 
    --pastelchart-7: 141 43% 62%; 
    --pastelchart-8: 110 50% 70%;
    --pastelchart-9: 92 62% 72%; 
    --pastelchart-10: 73 72% 75%; 

    --radius: 0.5rem;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --pastelchart-1: 246 109% 68%;
    --pastelchart-2: 254 174% 101%;
    --pastelchart-3: 230 246% 157%;
    --pastelchart-4: 170 222% 167%;
    --pastelchart-5: 100 194% 166%;
    --pastelchart-6: 115 115% 115%; 
    --pastelchart-7: 150 150% 150%; 
    --pastelchart-8: 191 191% 191%;
    --pastelchart-9: 209 209% 209%; 
    --pastelchart-10: 60 90% 55%; 

    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}


.overflow-x-auto::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.overflow-x-auto::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.overflow-x-auto::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}


.overflow-y-auto::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #f1f1f1;
  border-radius: 10px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.sknavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2C2E3D; /* 바 배경색 설정 */
  height: 40px; /* 네비게이션 바의 높이 지정 */
  position: relative; 
}

.sklogo {
  height: 32px; /* 로고 이미지 높이 설정 */
  /* 다른 로고 스타일을 적용하려면 필요한 스타일을 추가하세요 */
}


.rd3t-node text {
  stroke: none;
  fill: black; /* 텍스트 색상을 명확하게 설정 */
  paint-order: stroke; /* stroke가 fill보다 먼저 그려지지 않도록 설정 */
}

.rd3t-leaf-node text {
  stroke: none;
  fill: black; /* 텍스트 색상을 명확하게 설정 */
  paint-order: stroke;
  stroke: 0;
}


.node__root > g > rect{
  fill: rgb(217 249 157);
  stroke:  rgb(132 204 22);
}
 

/* .link__to-leaf{
  stroke: red!important;
}

.link__to-branch{
  stroke: blue!important;
} */