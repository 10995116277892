/* Custom CSS */
.swiper-pagination-progressbar {
    bottom: 0px !important; /* 하단으로 이동 */
    top: auto !important; /* 상단 위치 제거 */
    left: 0;
    right: 0;
    height: 4px; /* Progress bar 높이 조정 */
}

.swiper-wrapper{
    margin-top: -10px!important;
    margin-bottom: 10px;
}

.swiper-button-prev, .swiper-button-next {
    color: gray!important;
}

/* Apply opacity to all thumbnail slides */
.slider__thumbs .swiper-slide {
    opacity: 0.6;
}

/* Set opacity to 1 for the active thumbnail */
.slider__thumbs .swiper-slide-thumb-active {
    opacity: 1;
}